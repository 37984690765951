import React, { useState } from "react";
import {
  Text,
  Link,
  HStack,
  Center,
  Heading,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  VStack,
  Code,
  Flex,
  FormControl,
  Input,
  Button,
} from "native-base";
//import NativeBaseIcon from "./components/NativeBaseIcon";
import QRCode from "react-qr-code";

// Define the config
const config = {
  useSystemColorMode: false,
  initialColorMode: "light",
};

// extend the theme
export const theme = extendTheme({ config });


const UrlForm = () => {
  const [url, setUrl] = useState("http://www.bepic.com/balance");
  
  const onChange = (event) => {
    setUrl(event.target.value);
  };

  return (
    <Center
      _dark={{ bg: "blueGray.900" }}
      _light={{ bg: "blueGray.50" }}
      px={4}
      flex={1}
    >
      <VStack space={5} alignItems="center">
        <Heading size="lg">QR Code generator</Heading>
        <HStack space={2} alignItems="center">
          <FormControl
            isRequired
            borderColor="#6D6E719A"
            mt="32px"
            nativeID="url"
            maxW="500px"
            //w="80%"
            h="70px"
          >
            <FormControl.Label>Web cím</FormControl.Label>
            <Input
              type="text"
              required={true}
              variant="outline"
              fontSize="md"
              w="300"
              h="100%"
              placeholder="Írd be a webcímet"
              keyboardType=""
              onChange={onChange}
              value={url}
              //onSubmitEditing={props.onSendForm}
              autoFocus={true}
            />
            <FormControl.ErrorMessage bg="secundary.50">
              Hibás
            </FormControl.ErrorMessage>
          </FormControl>
        </HStack>
      </VStack>
      <br />
      <QRCode value={url} />
    </Center>
  );
};

export default function App() {
  return (
    <NativeBaseProvider theme={theme}>
      <UrlForm />
      {/*       <Center
        _dark={{ bg: "blueGray.900" }}
        _light={{ bg: "blueGray.50" }}
        px={4}
        flex={1}
      >
        <VStack space={5} alignItems="center">
          <NativeBaseIcon />
          <Heading size="lg">Welcome to NativeBase</Heading>
          <HStack space={2} alignItems="center">
            <Text>Edit</Text>
            <Code>App.js</Code>
            <Text>and save to reload.</Text>
          </HStack>
          <Link href="https://docs.nativebase.io" isExternal>
            <Text color="primary.500" underline fontSize={"xl"}>
              Learn NativeBase
            </Text>
          </Link>
          <ToggleDarkMode />
        </VStack>
      </Center> */}
    </NativeBaseProvider>
  );
}

// Color Switch Component
function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2} alignItems="center">
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        aria-label={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}
